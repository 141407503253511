import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const CONTACT_LOCALES = {
	WOLTAIR_CZ: 'kontakt',
	WOLTAIR_PL: 'kontakt',
	WOLTAIR_DE: 'kontakt',
	WOLTAIR_IT: 'contatto'
}

export const CONTACT = CONTACT_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return CONTACT === param
}) satisfies ParamMatcher
